import React from 'react';
import { default as Components, ComponentMappings } from '../components/blocks/components';
import Layout from '../components/layout/layout';
import { fetchAPI } from '../lib/api';
import settings from '../settings.json';
import { uid } from 'react-uid';
import Chip from '../components/custom/chip';
import { H1, Row } from '../components/styleguide-remapped/styleguide-remapped';
import { PrimarySearchBarWrapper, PrimaryLayoutWrapper } from '../assets/css/style';

const Home = ({ categories, homepage, pageProps }) => {
  const pageType = 'STARTPAGE';

  if (!homepage?.attributes) {
    return 'Keine Daten verfügbar.';
  }

  return (
    <Layout seo={homepage.attributes.seo} key={uid(homepage)} hideNav={true} pageType={pageType} documentData={homepage}>
      <PrimarySearchBarWrapper>
        <Row className="breadcrumb-row hidden-searchbar-element">
          <Chip title="ARD Hilfe" />
        </Row>
        <H1 className="index-page-headline"> {homepage.attributes.title} </H1>
        {React.createElement(ComponentMappings['block.searchbar'], {
          key: `comp-${uid(homepage.attributes.searchbar)}`,
          block: {
            ...{
              __component: 'block.searchbar',
              id: 'block.searchbar-' + uid(homepage.attributes.searchbar.id),
              mappingIndex: `comp-${uid(homepage.attributes.searchbar.id)}`,
            },
            ...homepage.attributes.searchbar,
          },
          documentData: homepage,
          pageType: pageType,
          relatedCategories: categories ?? [],
          relatedTopics: [],
        })}
      </PrimarySearchBarWrapper>
      <PrimaryLayoutWrapper>
        {React.createElement(ComponentMappings['block.related-categories'], {
          key: `comp-${uid(homepage.attributes.relatedCategories)}`,
          block: {
            ...{
              __component: 'block.related-categories',
              id: 'block.related-categories-' + uid(homepage.attributes.relatedCategories.id),
              mappingIndex: `comp-${uid(homepage.attributes.relatedCategories.id)}`,
            },
            ...homepage.attributes.relatedCategories,
          },
          documentData: homepage,
          pageType: pageType,
          relatedCategories: categories ?? [],
          relatedTopics: [],
        })}
        {React.createElement(ComponentMappings['block.contact-cta'], {
          key: `comp-${uid(homepage.attributes.contactCta)}`,
          block: {
            ...{
              __component: 'block.contact-cta',
              id: 'block.contact-cta-' + uid(homepage.attributes.contactCta.id),
              mappingIndex: `comp-${uid(homepage.attributes.contactCta.id)}`,
            },
            ...homepage.attributes.contactCta,
          },
          documentData: homepage,
          pageType: pageType,
          relatedCategories: categories ?? [],
          relatedTopics: [],
        })}
        {homepage.attributes.blockContent && homepage.attributes.blockContent.length
          ? homepage.attributes.blockContent.map((block, index) => {
              block.id = `${block.__component}-${index}`;
              block.mappingIndex = `comp-${uid(index)}`;

              if (settings.skipableElements.includes(block.__component)) {
                return false;
              }

              return Components(block, homepage, pageType, categories);
            })
          : null}
      </PrimaryLayoutWrapper>
    </Layout>
  );
};

export async function getStaticProps() {
  const [categoriesRes, homepageRes] = await Promise.all([
    fetchAPI('/categories', {
      populate: '*',
      sort: { rank: 'asc' },
    }),
    fetchAPI('/homepage', { populate: '*' }),
  ]);

  return {
    props: {
      categories: categoriesRes?.data ?? [],
      homepage: homepageRes?.data ?? {},
    },
    revalidate: settings.cacheRefreshSeconds,
  };
}

export default Home;
